<template>
  <div class="process-schedule-manage">
    <div class="process-schedule__filter">
      <select 
        name="mid" 
        class="filter__item filter__mid"
        v-model="mid.value"
      >
        <option value="%">전체</option>
        <option 
          v-for="m in mid.data"
          :value="m.id"
          :key="m.name"
        >{{ m.name }}</option>
      </select>
      <v-btn 
        class="filter-search-btn filter-fn-btn rounded-0"
        :class="{ 'mac': is_mac, }"
        depressed small
        @click='search'
      >검색</v-btn>
    </div>
    <div class="process-schedule__content">
      <div class="process-schedule-table">
        <table>
          <thead :class="{ 'mac': is_mac, }">
            <tr>
              <th>호기</th>
              <th>기계명</th>
              <th colspan="5">예정 공정 목록</th>
            </tr>
          </thead>
          <tbody>

            <tr
              v-for="machine, i in trim_data"
              :key="'machine' + i"
            >
              <td class="id">
                {{ machine[0].machine_no }}
              </td>
              <td class="mid">
                {{ machine[0].mid }}
              </td>
              <th>
                <p class="column head-date">
                  날짜
                </p>
                <p class="column head-process">
                  공정명
                </p>
                <p class="column head-count">
                  수량
                </p>
              </th>
              <td 
                class="process-list"
                :class="{ 'mac': is_mac, }"
                v-for="item, item_i in machine"
                :key="item.mid + '-' + item.product_name + '-' + item_i"
              >
                <div class="process-list__actions">
                  <v-btn 
                    class="process-modify"
                    @click="openProcessEdit(item)"
                    fab text x-small
                  >
                    <v-icon>$modify</v-icon>
                  </v-btn>
                  <v-btn 
                    class="process-delete"
                    fab text x-small
                    @click="confirmDeleteProcess(item)"
                  >
                    <v-icon>$deleteIcon</v-icon>
                  </v-btn>
                </div>
                <p class="column date">{{ dateTimeFormatter(item.estimated_start) }}</p>
                <p class="column process">{{ item.product_name }}</p>
                <p class="column count">{{ item.estimated_quantity }}</p>
              </td>
              <td
                v-for="extra in (4 - machine.length)"
                :key="'extra' + extra"
                class="empty-column process-list"
              >
                <p class="column"></p>
                <p class="column"></p>
                <p class="column"></p>
                <!-- <span class="empty-text">empty</span> -->
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>

    <v-dialog 
      v-model="dialog.open"
      content-class="process-edit-dialog rounded-0"
      @click:outside="processEditCancle"
      dark
    >
      <v-card class="inner">
        <p class="dialog-title">공정 편집</p>
        <div class="dialog__content">
          <label class="dialog__form-label">
            <span class="label-text">날 짜</span>
            <dayTimePicker
              class="label-field"
              :preset="{date:dialog.date,time:dialog.time}"
              :fieldHeight="38"
              :clickPick="true"
              :offsetX="true"
              :offsetY="false"
              @picked="setDayTime"
            >
            </dayTimePicker>
          </label>
          <label class="dialog__form-label">
            <span class="label-text">수 량</span>
            <v-text-field 
              class="label-field rounded-0"
              type="number"
              v-model ='dialog.estimated_quantity'
              suffix="개"
              solo flat dense hide-details light
            >
            </v-text-field>
          </label>
          <label class="dialog__form-label">
            <span class="label-text">가 공 시 간</span>
            <v-text-field 
              class="label-field rounded-0"
              type="number"
              suffix="초"
              v-model='dialog.estimated_active_time' 
              solo flat dense hide-details light
            >
            </v-text-field>
          </label>
              <label 
                class="dialog__form-label"
              >
                <span class="label-text">세 팅 시 간</span>
                <v-text-field 
                  class="label-field rounded-0"
                  suffix="분"
                  type="number"
                  v-model='dialog.estimated_setting_time' 
                  solo flat dense hide-details light
                >
                </v-text-field>
              </label>
   
        </div>
        <v-card-actions class="dialog__actions" :class="{ 'mac': is_mac, }">
          <v-btn 
            class="action-btn edit-cancle"
            @click="processEditCancle"
            depressed light
          >취소</v-btn>
          <v-btn 
            class="action-btn edit-comlete"
            @click="processEditComplete"
            depressed light
          >확인</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog 
      v-model="delete_program.open"
      content-class="process-delete-confirm"
      dark
    >
      <v-card class="inner">
        <div class="dialog__content">
          <p class="dialog__confirm-text">
            <strong>{{ dateTimeFormatter(delete_program.time) }}</strong>에 등록된 <br>
            <strong>{{ delete_program.program }}</strong> 공정을<br>
            정말 삭제하시겠습니까?
          </p>
        </div>
        <v-card-actions class="dialog__actions" :class="{ 'mac': is_mac, }">
          <v-btn 
            class="action-btn delete-cancle" 
            @click="delete_program.open = false"
            depressed light
          >취소</v-btn>
          <v-btn 
            class="action-btn delete-complete" 
            @click="deleteProcess"
            depressed light
          >확인</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import moment from 'moment';
import dayTimePicker from '../components/dayTimePicker.vue';

export default {
  components: {
    dayTimePicker, moment,
  },
  data() {
    return {
      today: moment().format('YYYY-MM-DD'),
      mid: {
        data: [
          { name: 'mid1', id: '1'},
          { name: 'mid2', id: '2'},
        ],
        value: null,
      },
      data: [
        {
          mid: 'Lynx220LSY',
          machine_no: 31,
          estimated_start: '2021-09-30 11:30',
          product_name: '900131-00145-02',
          estimated_quantity: 1000,
        },
        {
          mid: 'Lynx220LSY',
          machine_no: 32,
          estimated_start: '2021-09-30 11:30',
          product_name: '900131-00145-02',
          estimated_quantity: 3500,
        },
        {
          mid: 'Lynx220',
          machine_no: 16,
          estimated_start: '2021-09-30 11:30',
          product_name: '900131-00145-02',
          estimated_quantity: 1200,
        },
        {
          mid: 'Puma240',
          machine_no: 32,
          estimated_start: '2021-09-30 11:30',
          product_name: '900131-00145-02',
          estimated_quantity: 3500,
        },
        {
          mid: 'Puma240',
          machine_no: 16,
          estimated_start: '2021-09-30 11:30',
          product_name: '900131-00145-02',
          estimated_quantity: 1200,
        },
        {
          mid: 'Puma280',
          machine_no: 16,
          estimated_start: '2021-09-30 11:30',
          product_name: '900131-00145-02',
          estimated_quantity: 1200,
        },
        {
          mid: 'Puma280',
          machine_no: 16,
          estimated_start: '2021-09-30 11:30',
          product_name: '900131-00145-02',
          estimated_quantity: 1200,
        },
        {
          mid: 'Puma280',
          machine_no: 16,
          estimated_start: '2021-09-30 11:30',
          product_name: '900131-00145-02',
          estimated_quantity: 1200,
        },
        {
          mid: 'Puma2w80',
          machine_no: 16,
          estimated_start: '2021-09-30 11:30',
          product_name: '900131-00145-02',
          estimated_quantity: 1200,
        },
        {
          mid: 'Puma2w80',
          machine_no: 16,
          estimated_start: '2021-09-30 11:30',
          product_name: '900131-00145-02',
          estimated_quantity: 1200,
        },
      ],
      trim_data: [],
      dialog: {
        open: false,
        id:null,
        date: moment().format('YYYY-MM-DD'),
        time: moment().format('HH:mm'),
        estimated_active_time:null,
        estimated_quantity: null,
        estimated_setting_time :null,
      },
      delete_program: {
        open: false,
        time: '',
        id:null,
        program: '',
      }
    };
  },
  methods: {
    getMid(){
      this.$http.APS.get('/idle/key').then(r=>{
        this.mid.data = r.data;
      })
    },
    trimProcessData() {
      const data = this.data;
      let trim = {};
      for( let i = 0; i < data.length; i++ ) {
        const tg = data[i];
        if( trim[tg.mid] == undefined ) { trim[tg.mid] = [] };
        trim[tg.mid].push(tg);
      }
      this.trim_data = trim;
    },
    confirmDeleteProcess(data) {
      const del = this.delete_program;
      del.open = true;
      del.program = data.product_name;
      del.time = data.estimated_start;
      del.id = data.id;
    },
    async deleteProcess() {
      const del = this.delete_program;
      await this.$http.APS.patch('/order/delete',{id:del.id});
      await this.getData();
      del.open = false;
    },
    openProcessEdit(item) {
      const dia = this.dialog;
      dia.open = true;
      dia.id = item.id;
      dia.estimated_active_time = item.estimated_active_time;
      dia.date = item.estimated_start.substr(0,10);
      dia.time = item.estimated_start.substr(11,5);
      dia.estimated_quantity = item.estimated_quantity;
      dia.estimated_setting_time = item.estimated_setting_time/60;
    },
    setDayTime(v){
      const dialog = this.dialog;
      dialog.date = v.date;
      dialog.time = v.time;
    },
    processEditReset() {
      const dia = this.dialog;
      dia.open = false;
      dia.date = this.today;
      dia.time = moment().format('hh:mm');
    },
    processEditCancle() {
      const dia = this.dialog;
      this.processEditReset();
    },
    async processEditComplete() {
      const dia = this.dialog;
      dia.estimated_setting_time = dia.estimated_setting_time*60;
      dia.estimated_start = dia.date +' '+dia.time;
      var math = dia.estimated_setting_time + (dia.estimated_active_time * dia.estimated_quantity) ;
      dia.estimated_end = moment(dia.estimated_start).add(math,'seconds').format('YYYY-MM-DD HH:mm:ss');
      await this.$http.APS.patch('/order/modi',dia);
      await this.getData();
      await this.processEditReset();
    },
    dateTimeFormatter(v){
      v = v.substr(0,16).replace('T',' ');
      return v;
    },
    search(){
      this.getData();
    },
    async getData(){
      var map = {mkey:this.mid.value}
      await this.$http.APS.post('/order/',map).then(r=>{
        this.data = r.data;
      })
      await this.trimProcessData();
    },
  },
  computed: {
    is_mac() {
      return this.$is_mac;
    }
  },
  mounted() {
    this.mid.value ='%';
    this.getMid();
    this.getData();
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/global.scss';

  .process-schedule-manage {
    @extend .flex_column;

    height: 100%;
    justify-content: stretch;
  }

  .process-schedule__filter {
    @extend .common-filter;

    flex: 0 0 auto;
    justify-content: flex-end;
    padding: 28px 64px;

    .filter__mid {
      @extend .common-select;
    }
    .filter-search-btn {
      width: 70px;
      height: 40px;

      &.mac {
        padding-top: 3px;
      }
    }
  }

  .process-schedule__content {
    flex: 1 1 0;
    width: 100%;
    overflow-y: auto;
    position: relative;
  }

  .process-schedule-table {
    width: 100%;

    ::v-deep table {
      width: 100%;
      background-color: #F2F6F9;

      thead {
        width: 100%;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 3;

        tr {
          width: 100vw;
        }

        &.mac {

          th {
            padding-top: 2px;
          }
        }
        
        th {
          height: 50px;
          vertical-align: middle;
          font-size: 19px;
          font-weight: 500;
          background-color: $skyblue-darken1;

          &:nth-child(odd) {
            background-color: #B2CDD9;
          }
        }
      }

      td {
        vertical-align: middle;
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
      }
      .column {
        @extend .flex_row;

        width: 100%;
        height: 64px;
      }

      tbody {
        
        tr {
          
          th {
            border-bottom: 1px solid rgba(0, 0, 0, 0.5);

            .column {
              display: block;
              padding: 0 !important;
              font-size: 17px;
              line-height: 64px;
              font-weight: 500;
              text-align: center;
            }
          }
          td {
            text-align: center;
            font-size: 17px;
            
            &:nth-child(even) {
              background-color: white;
            }

            &.id {
              font-size: 21px;
            }
            &.mid {
              font-size: 21px;
            }
            &.process-list {
              width: 18%;
            }

          }

          .column {
            @extend .flex_row;

            width: 100%;
            text-align: left;
            padding: 0 60px;
            font-weight: 400;

            border-bottom: 1px solid #89898950;

            &:last-child {
              border-bottom: 0;
            }
          }
          .process-list {
            position: relative;

            &:hover {

              .process-list__actions {
                display: flex;
              }
            }
            &.mac {

              .column {
                padding-top: 2px;
              }
            }

            &__actions {
              @extend .flex_row;

              display: none;
              justify-content: flex-end;
              align-items: flex-start;
              width: 100%;
              height: 100%;
              padding: 12px;
              position: absolute;
              top: 0;
              left: 0;
              background-color: rgba(0, 0, 0, 0.15);

              .v-icon {

                .cls-1 {
                  fill: black;
                }
              }
            }
          }
        }
      }
    }
  }

  ::v-deep .process-edit-dialog {
    @extend .dialog--black-theme;
  }
  .process-edit__time-menu {
    width: fit-content;
    min-width: unset !important;
  }
  ::v-deep .process-delete-confirm {
    @extend .dialog--black-theme;
  }
</style>