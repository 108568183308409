<template>
  <div class="day-time-picker">
    <v-menu
      content-class="picker-menu"
      :offset-y="offsetY"
      :offset-x="offsetX"
      :left="left"
      :right="right"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ attr, on }">
        <v-text-field 
          class="picker-field rounded-0"
          :class="{ 'picker--border': border, 'picker--prepend': style.icon.prepend }"
          v-model="data.value"
          v-bind="attr"
          v-on="on"
          :height="fieldHeight"
          readonly hide-details solo flat dense light
        >
          <v-icon
            v-if="style.icon.prepend"
            :slot="style.icon.inner ? 'prepend-inner' : 'prepend'"
            size="18"
            class="prepend-icon"
          >$pickerCalendar</v-icon>
          <v-icon
            v-else
            :slot="style.icon.outer ? 'append-outer' : 'append'"
            size="18"
            class="append-icon"
          >$pickerCalendar</v-icon>
        </v-text-field>
      </template>
      <v-card class="inner">
        <v-date-picker
          class="picker rounded-0"
          locale="ko"
          :title-date-format="titleFormatter"
          :day-format="dayFormatter"
          color="#556b84"
          v-model="data.date"
        ></v-date-picker>
        <v-time-picker
          class="picker rounded-0"
          color="#556b84"
          v-model="data.time"
          format="24hr"
        ></v-time-picker>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: 'dayTimePicker',
  data() {
    return {
      today: moment().format('YYYY-MM-DD'),
      data: {
        date: this.preset.date,
        time: this.preset.time,
        value: this.preset.date + ' ' + this.preset.time,
      },
      style: {
        icon: {
          prepend: this.presetIcon[0] == 'prepend' ? true : false,
          inner: this.presetIcon[1],
          outer: this.presetIcon[2],
        }
      }
    };
  },
  props: {
    preset: {
      type: Object,
      default() {
        return { date: moment().format('YYYY-MM-DD'), time: '12:00' };
      },
    },
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    offsetY: {
      type: Boolean,
      default: false,
    },
    offsetX: {
      type: Boolean,
      default: false,
    },
    fieldHeight: {
      type: Number,
      default: 35,
    },
    presetIcon: {
      type: Array,
      default() {
        return [ 'prepend', false, false ];
      },
    },
    border: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        const data = this.data; 
        this.$emit('picked', { date: data.date, time: data.time });
        data.value = data.date + ' ' + data.time;
      }
    }
  },
  methods: {
    titleFormatter(data) {
      const date = data.split('-');
      return date[1] + '월 ' + date[2] + '일';
    },
    dayFormatter(value) {
      value = value.split('-')[2];
      return Number(value);
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/global.scss';

  .day-time-picker {
    margin: 0 !important;

    ::v-deep .picker-field {
      width: 200px;
      align-items: center;

      &.picker--border {
        border: 1px solid $main_gray !important;
      }
      .v-input__prepend-outer {
        margin: 0 !important;
        padding-left: 12px;
      }
      
      .v-input__slot {
        padding: 0 12px !important;
        font-size: 14px;
        background: white !important;
  
        // input {}
      }
    }

  }
  .picker-menu {
    
    .inner {
      @extend .flex_row;
  
      align-items: flex-start;
    }
    ::v-deep .picker {
      
      .v-picker__title {
        height: 105px !important;

        .v-date-picker-title__date {
          padding-top: 4px;
        }
      }
    }
  }

</style>